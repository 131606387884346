<script setup>
import { mdiForwardburger, mdiBackburger, mdiMenu } from "@mdi/js";
import { ref } from "vue";
import { useRouter } from "vue-router";
import menuAside from "@/menuAside.js";
import menuNavBar from "@/menuNavBar.js";
import { useMainStore } from "@/stores/main.js";
import { useStyleStore } from "@/stores/style.js";
import BaseIcon from "@/components/BaseIcon.vue";
import FormControl from "@/components/FormControl.vue";
import NavBar from "@/components/NavBar.vue";
import NavBarItemPlain from "@/components/NavBarItemPlain.vue";
import AsideMenu from "@/components/AsideMenu.vue";
import FooterBar from "@/components/FooterBar.vue";
import PocketBase from "pocketbase";
import { BASE_API_URL } from "@/config.js";
import DropdownSelect from "@/components/DropdownSelect.vue";
import CompanySelect from "@/components/CompanySelect.vue";
import buzzTrackerLogo from "@/assets/buzztracker-logo.png";

const pb = new PocketBase(BASE_API_URL);

const layoutAsidePadding = "xl:pl-60";

const styleStore = useStyleStore();
const mainStore = useMainStore();

const router = useRouter();

const isAsideMobileExpanded = ref(false);
const isAsideLgActive = ref(false);

router.beforeEach(() => {
  isAsideMobileExpanded.value = false;
  isAsideLgActive.value = false;
});

const menuClick = (event, item) => {
  if (item.isToggleLightDark) {
    styleStore.setDarkMode();
  }

  if (item.isLogout) {
    pb.authStore.clear();
    router.push("/login");
    mainStore.setUser(null);
  }
};
</script>

<template>
  <div :class="{
    dark: styleStore.darkMode,
    'overflow-hidden lg:overflow-visible': isAsideMobileExpanded,
  }">
    <div :class="[layoutAsidePadding, { 'ml-60 lg:ml-0': isAsideMobileExpanded }]"
      class="xl:pt-14 pt-28 min-h-screen w-screen transition-position lg:w-auto bg-gray-50 dark:bg-slate-800 dark:text-slate-100">
      <NavBar :menu="menuNavBar" :class="[
        layoutAsidePadding,
        { 'ml-60 lg:ml-0': isAsideMobileExpanded },
      ]" @menu-click="menuClick">
        <template #top>
          <NavBarItemPlain display="flex lg:hidden" @click.prevent="isAsideMobileExpanded = !isAsideMobileExpanded">
            <BaseIcon :path="isAsideMobileExpanded ? mdiBackburger : mdiForwardburger" size="24" />
          </NavBarItemPlain>
          <NavBarItemPlain display="hidden lg:flex xl:hidden" @click.prevent="isAsideLgActive = true">
            <BaseIcon :path="mdiMenu" size="24" />
          </NavBarItemPlain>

          <div class="flex items-center justify-center block xl:hidden">
            <img :src="buzzTrackerLogo" alt="BuzzTracker Logo" class="mx-3 h-auto max-h-8" />
            <div class="text-center">
              <b class="font-black">BuzzTracker</b>
            </div>
          </div>

          <NavBarItemPlain class="hidden md:block">
            <h3 class="font-bold leading-tight m-0 block" v-if="mainStore.entities && mainStore.entities.length > 1">
              <DropdownSelect
                :selectOption="mainStore.setSelectedEntity"
                :preselectedOption="mainStore.selectedEntity"
                v-if="mainStore.entities"
                :options="mainStore.entities"
              />
            </h3>
          </NavBarItemPlain>
        </template>
        <template #bottom>
          <NavBarItemPlain>
            <div class="font-bold leading-tight xl:hidden block">
              <CompanySelect  v-if="mainStore.selectedCompany"/>
            </div>
          </NavBarItemPlain>
          <NavBarItemPlain class="md:hidden block">
            <h3 class="font-bold leading-tight m-0 block" v-if="mainStore.entities && mainStore.entities.length > 1">
              <DropdownSelect
                :selectOption="mainStore.setSelectedEntity"
                :preselectedOption="mainStore.selectedEntity"
                v-if="mainStore.entities"
                :options="mainStore.entities"
              />

            </h3>
          </NavBarItemPlain>
        </template>
      </NavBar>

      <AsideMenu :is-aside-mobile-expanded="isAsideMobileExpanded" :is-aside-lg-active="isAsideLgActive"
        :menu="menuAside" @menu-click="menuClick" @aside-lg-close-click="isAsideLgActive = false" />


      <div @click="isAsideMobileExpanded = false">
        <slot />
      </div>

      <FooterBar>
        <!-- Get more with
        <a
          href="https://tailwind-vue.justboil.me/"
          target="_blank"
          class="text-blue-600"
          >Premium version</a
        > -->
      </FooterBar>
    </div>
  </div>
</template>
